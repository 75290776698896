import styles from '@pages/CompanyPassport/CommonInfo.module.scss'
import { Typography } from '@pages/CompanyPassport/Typography/Typography'
import { CompanyStatistic } from '@pages/CompanyPassport/CompanyStatistic'
import React, { useState } from 'react'
import moment from 'moment'
import { Modal, ModalBackButton } from '@/components/Modal'
import { Title } from '@/ui'
import { Button } from '@/ui/atoms'
import { CompanyPassportModal } from '@pages/CompanyPassport/CompanyPassportModal'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'

const Row = ({ title, value }) => {
	return (
		<>
			<div>
				<Typography variant={'subtitle'}>{title}</Typography>
			</div>
			<div>
				<Typography variant={'text'}>{value}</Typography>
			</div>
		</>
	)
}

const PassportIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
			<path
				stroke="#17B67C"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="m18.4 6.4-2.8-2.8a2 2 0 0 0-1.4-.6H7a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h10a2 2 0 0 0 2-2V7.8c0-.5-.2-1-.6-1.4v0ZM8 12.2h8m-8 3h8M8 18h5"
			/>
			<path
				stroke="#17B67C"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M8 8.3V6.7c0-.4.3-.7.7-.7h1.6c.4 0 .7.3.7.7v1.6c0 .4-.3.7-.7.7H8.7a.7.7 0 0 1-.7-.7Z"
			/>
		</svg>
	)
}

const firstDayOfCurrentMonth = moment([moment().year(), moment().month(), 1])
const formattedDate = firstDayOfCurrentMonth.format('DD.MM.YYYY')

export const CommonInfo = ({ passport }) => {
	const { t } = useTranslation()
	const [isOpenFullInfo, setIsOpenFullInfo] = useState(false)
	const lang = getCurrentLanguage()
	const langPrefix = lang === 'rus' ? 'Ru' : 'Kz'

	return (
		<div className={styles.common_info_container}>
			{isOpenFullInfo && (
				<CompanyPassportModal
					onClose={() => {
						setIsOpenFullInfo(false)
					}}
				></CompanyPassportModal>
			)}
			<div className={styles.table}>
				<Row title={t('ckp_organizational_form')} value={passport[`organizationForm${langPrefix}`]}></Row>
				<Row title={t('ckp_BIN_IIN')} value={passport.bin}></Row>
				<Row title={t('ckp_registration_date')} value={passport.regActionDate}></Row>
				<Row title={t('ckp_ownership_right')} value={passport[`ownershipName${langPrefix}`]}></Row>
				<Row title={''} value={''}></Row>
				<Row title={t('ckp_legal_address')} value={passport[`addressName${langPrefix}`]}></Row>
				<Row title={t('ckp_region')} value={passport[`region${langPrefix}`]}></Row>
				<Row title={'E-mail:'} value={passport.email}></Row>
				<Row title={t('ckp_phone')} value={passport.phone}></Row>
				<Row title={t('ckp_manager')} value={passport.leaderName}></Row>
			</div>

			<div className={styles.full_passport_container} onClick={() => setIsOpenFullInfo(true)}>
				<PassportIcon></PassportIcon>
				<Typography variant={'text_button'}>{t('ckp_full_enterprise_passport')}</Typography>
			</div>

			<div className={styles.statistic_container}>
				<div className={styles.statistic_container_header}>
					<Typography variant={'title'}>{t('ckp_statistics')}</Typography>
					<Typography variant={'text'}>
						{t('ckp_info_on_violations_updated_monthly')}: {formattedDate}
					</Typography>
				</div>

				<CompanyStatistic passport={passport}></CompanyStatistic>
			</div>
		</div>
	)
}
