import React, { useCallback, useEffect, useState } from 'react'
import styles from '../EmployeesInNeed.module.scss'
import { Button } from '@/ui'
import addWhiteIcon from 'assets/pages/personalAffairs/addWhite.svg'
import { useTranslation } from 'react-i18next'
import Search from '@pages/PersonalAffairs/Tabs/WorkerWelfareMainTab/EmployeesInNeed/Table/Search'
import { Table } from '@pages/PersonalAffairs/Tabs/WorkerWelfareMainTab/EmployeesInNeed/Table/Table'
import { HrPagination } from '@/components/Pagination'
import { getAid } from '@/shared/api/rest/personalAffairs/getAid'
import TabsUnstyled from '@mui/base/Tabs'
import TabsList from '@/ui/atoms/tabsList'
import Tab from '@/ui/atoms/tab'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
const ReportList = ({ aidData, setAidData, filters, setFilters, setOpenModal, setLoading }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const initialQueryState = {
		page: 0,
		pageSize: 10,
		rowsPerPage: [
			{ size: 10, label: '10' },
			{ size: 30, label: '30' },
			{ size: 50, label: '50' },
			{ size: 100, label: '100' },
		],
	}
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const [queryParams, setQueryParams] = useState(initialQueryState)
	const [category, setCategory] = useState(null)
	const handleChangePage = (e, page) => setQueryParams({ ...queryParams, page: page - 1 })
	const handleChangeRowsPerPage = (size) => setQueryParams((prev) => ({ ...prev, page: 0, pageSize: size }))

	const fetchData = useCallback(
		async (cat, _filters = filters, _queryParams = queryParams) => {
			if (cat) {
				setQueryParams({ ...queryParams, page: 0 })
				setCategory(cat)
			}
			const { page, pageSize } = _queryParams
			try {
				setLoading(true)
				const aid = await getAid(cat ? 0 : page, pageSize, activeCompany.bin, _filters.srcText, cat ?? category)
				setAidData(aid)
			} catch (error) {
				setAidData(null)
			} finally {
				setLoading(false)
			}
		},
		[filters, queryParams]
	)
	useEffect(() => fetchData(), [queryParams.page, queryParams.pageSize])
	return (
		<>
			<Button buttonClassName={styles.wrapper__button} icon={addWhiteIcon} onClick={() => setOpenModal(true)}>
				{language === 'rus'
					? 'Обновить список работников из категорий: Неблагополучная, Кризисная, Экстренная'
					: 'Жұмысшылар тізімін санаттардан жаңартыңыз: қолайсыз, дағдарыс, төтенше жағдай'}
			</Button>
			<div className={styles.tabs}>
				<TabsUnstyled defaultValue={0} onChange={(e) => fetchData(e.target.id)}>
					<TabsList>
						<Tab value={0} id={'all'}>
							{t('welfare.tabs.tabs.all')}
						</Tab>
						<Tab value={1} id={'d'}>
							{t('welfare.tabs.tabs.d')}
						</Tab>
						<Tab value={2} id={'e'}>
							{t('welfare.tabs.tabs.e')}
						</Tab>
					</TabsList>
				</TabsUnstyled>
			</div>
			<Search filters={filters} setFilters={setFilters} aidData={aidData} fetchData={() => fetchData()} />
			<Table data={aidData.aidDtos?.content ?? []} />
			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={queryParams.rowsPerPage}
				rowsPerPage={queryParams.pageSize}
				numberOfElements={aidData.aidDtos?.numberOfElements}
				totalElements={aidData.aidDtos?.totalElements}
				totalPages={aidData.aidDtos?.totalPages}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				page={queryParams.page}
			/>
		</>
	)
}

export default ReportList
