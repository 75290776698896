import { useEffect, useState } from 'react'

export const useFetchData = ({ bin, fetchFunction, defaultValue }) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState(defaultValue)
	const [isEmpty, setIsEmpty] = useState(false)

	useEffect(async () => {
		if (!bin) return
		try {
			setLoading(true)
			setIsEmpty(false)

			// для теста
			const data = await fetchFunction(bin)

			// const data = await safetyService.getBasicInfo('951140000042')
			// const objects = await safetyService.getObjects('951140000042')
			// const objects = await safetyService.getObjects(activeCompany.bin)
			// const data = await safetyService.getBasicInfo(activeCompany.bin)

			if (!data) {
				setIsEmpty(true)
				return
			}
			setData(data)
		} catch (error) {
			setIsEmpty(true)
			setLoading(false)
		} finally {
			setLoading(false)
		}
	}, [bin, fetchFunction, defaultValue, setData])

	return { loading, data, isEmpty }
}
