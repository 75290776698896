import styles from '@pages/CompanyPassport/CompanyObjects.module.scss'
import { InfoGreen } from '@app/icons'
import React, { Fragment } from 'react'
import { Typography } from '@pages/CompanyPassport/Typography/Typography'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'

const StatusMap = {
	1: 'ckp_active',
	2: 'ckp_inactive',
}

const StatusColorMap = {
	1: 'text_button_green',
	2: 'text_button_red',
}

export const CompanyObjects = ({ objects }) => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const langPrefix = lang === 'rus' ? 'Ru' : 'Kz'

	return (
		<div className={styles.objectsContainer}>
			<div className={styles.infoContainer}>
				<InfoGreen className={styles.infoContainerIcon} />
				<Typography variant={'text_button_green'}>{t('ckp_object_data_in_this_tab')}</Typography>
			</div>

			<div className={styles.objectsScrollContainer}>
				{objects.map((object) => (
					<Fragment key={object?.objectId}>
						<div className={styles.itemObjectContainer}>
							<div className={styles.itemPart}>
								<Typography variant={'text_sm'}>{object.bin}</Typography>
								<Typography variant={'text_m'}>{object[`name${langPrefix}`]}</Typography>
							</div>
							<div className={styles.itemGrid}>
								<Typography variant={'subtitle'}>{t('ckp_legal_address')}: </Typography>
								<Typography variant={'text'}>{object[`address${langPrefix}`]}</Typography>
								<Typography variant={'subtitle'}>{t('ckp_status')}: </Typography>
								<Typography variant={StatusColorMap[object.status]}>{t(StatusMap[object.status])}</Typography>
							</div>
						</div>
						<hr></hr>
					</Fragment>
				))}
			</div>
		</div>
	)
}
