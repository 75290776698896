import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import styles from './Checks.module.scss'
import { Typography } from '@pages/CompanyPassport/Typography/Typography'
import { Indicator } from '@pages/CompanyPassport/CompanyInsurance'
import { Table, TableColumn } from '@/components/Table'
import { HrPagination } from '@/components/Pagination'
import React, { useCallback, useEffect } from 'react'
import { useFetchData } from '@pages/CompanyPassport/hooks/useFetchData'
import { defaultInsurance } from '@pages/CompanyPassport/dfault'
import { safetyService } from '@/shared/api/rest/safety/safetyService'
import { LoadingBlocker } from '@/components/Loader'
import { InfoDevelopment } from '@/components/infoDevelopment'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'

const NoteIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
			<rect width="40" height="40" fill="#F0F9F4" rx="20" />
			<g stroke="#17B67C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#a)">
				<path d="M28.2 20.6a2 2 0 1 1 2.9 2.8l-6.8 6.8c-.2.2-.5.3-.8.3h-2.3v-2.4c0-.3 0-.6.3-.8l6.7-6.7v0Zm-14-4.1h9.3m-9.3 4.7H20m-5.8 4.6h3.5m11.9-.8-2.9-3m1.5-5.5V13c0-1.3-1-2.3-2.4-2.3h-14c-1.3 0-2.3 1-2.3 2.3v15.2c0 1.3 1 2.3 2.3 2.3h4.7m7-21v2.3m-4.7-2.3v2.3m-4.6-2.3v2.3" />
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M6 6h28v28H6z" />
				</clipPath>
			</defs>
		</svg>
	)
}

const OrangeNoteIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
			<rect width="40" height="40" fill="#FFF1DC" rx="20" />
			<g clipPath="url(#a)">
				<g stroke="#F3A126" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#b)">
					<path d="m15.3 10.7 1.6-2c.2-.2.5-.4.9-.4h4.4c.4 0 .7.2 1 .5l1.5 1.9v1.1c0 .7-.6 1.2-1.2 1.2h-7c-.6 0-1.2-.5-1.2-1.2v-1.1 0Z" />
					<path d="M15.3 10.7H13c-1.3 0-2.3 1-2.3 2.3v15.2c0 1.3 1 2.3 2.3 2.3h14c1.3 0 2.3-1 2.3-2.3V13c0-1.3-1-2.3-2.3-2.3h-2.3m-9.4 8.1h9.4m-9.4 5.9h9.4" />
				</g>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M6 6h28v28H6z" />
				</clipPath>
				<clipPath id="b">
					<path fill="#fff" d="M6 6h28v28H6z" />
				</clipPath>
			</defs>
		</svg>
	)
}

const RedNoteIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" fill="none">
			<rect width="40" height="40" x=".5" fill="#FBEFEF" rx="20" />
			<g clipPath="url(#a)">
				<g stroke="#F04E47" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#b)">
					<path d="M27.5 15.3v-3.5c0-1.3-1-2.3-2.3-2.3H12.3c-1.3 0-2.3 1-2.3 2.3v16.4c0 1.3 1 2.3 2.3 2.3h3.5" />
					<path d="M15.3 25.8a1.8 1.8 0 1 1 0-3.5 1.8 1.8 0 0 1 0 3.5ZM31 28.9v-1c0-.6-.3-1-.8-1.2l-2.7-.9v-2.3l.8-.5a1.6 1.6 0 0 0-.9-3h-3.3a1.6 1.6 0 0 0-1 3l.9.5v2.3l-2.7 1c-.5 0-.8.5-.8 1v1c0 .4.1.7.3.9l.5.5c.2.2.5.3.8.3h7.3c.3 0 .6-.1.8-.3l.5-.5c.2-.2.3-.5.3-.8ZM13.5 14.2h9.3m-9.3 4.1h4.7" />
				</g>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M6.5 6h28v28h-28z" />
				</clipPath>
				<clipPath id="b">
					<path fill="#fff" d="M6.5 6h28v28h-28z" />
				</clipPath>
			</defs>
		</svg>
	)
}

const RestrictIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
			<path
				fill="#FC1313"
				fillRule="evenodd"
				d="M7 1.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM.5 7a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Zm4.3-2.2c.2-.2.5-.2.7 0L7 6.3l1.5-1.5a.5.5 0 0 1 .7.7L7.7 7l1.5 1.5a.5.5 0 0 1-.7.7L7 7.7 5.5 9.2a.5.5 0 0 1-.7-.7L6.3 7 4.8 5.5a.5.5 0 0 1 0-.7Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

const WaitIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
			<path
				fill="#F19204"
				fillRule="evenodd"
				d="M6.8 3.8c.3 0 .5.2.5.5v2.8l2.2 1.4a.5.5 0 1 1-.5.8L6.6 8a.5.5 0 0 1-.3-.5v-3c0-.4.2-.6.5-.6Z"
				clipRule="evenodd"
			/>
			<path
				fill="#F19204"
				fillRule="evenodd"
				d="M7 1.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM.5 7a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

const WaitIconGreen = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
			<path
				fill="#17B67C"
				fillRule="evenodd"
				d="M6.8 3.8c.3 0 .5.2.5.5v2.8l2.2 1.4a.5.5 0 1 1-.5.8L6.6 8a.5.5 0 0 1-.3-.5v-3c0-.4.2-.6.5-.6Z"
				clipRule="evenodd"
			/>
			<path
				fill="#17B67C"
				fillRule="evenodd"
				d="M7 1.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM.5 7a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

const DoneIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
			<path
				fill="#17B67C"
				fillRule="evenodd"
				d="M8 2.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM1.5 8a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0ZM11 6.3c.2.2.2.5 0 .7l-3.3 3.4H7l-2-2a.5.5 0 1 1 .7-.8l1.6 1.7 3-3c.2-.2.5-.2.7 0Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
const PauseIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
			<path
				fill="#F19204"
				fillRule="evenodd"
				d="M8 2.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM1.5 8a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Zm5.2-2.5c.2 0 .5.2.5.5v4a.5.5 0 0 1-1 0V6c0-.3.2-.5.5-.5Zm2.6 0c.3 0 .5.2.5.5v4a.5.5 0 0 1-1 0V6c0-.3.3-.5.5-.5Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

const mapIconToStatusRu = {
	'Отказано в регистрации ЕРСОП': <RestrictIcon />,
	'Отменена проверка': <RestrictIcon />,
	'Регистрация в ЕРСОП': <WaitIcon />,
	'На доработку в ГО': <WaitIcon />,
	'Зарегистрирована в ЕРСОП': <DoneIcon />,
	Приостановлена: <PauseIcon />,
	Завершена: <DoneIcon />,
	Возобновлена: <WaitIconGreen />,
}

const mapColorsToStatusRu = {
	'Отказано в регистрации ЕРСОП': '#FC1313',
	'Отменена проверка': '#FC1313',
	'Регистрация в ЕРСОП': '#F19204',
	'На доработку в ГО': '#F19204',
	'Зарегистрирована в ЕРСОП': '#17B67C',
	Приостановлена: '#F19204',
	Завершена: '#17B67C',
	Возобновлена: '#17B67C',
}

const mapIconToStatusKz = {
	'ТСОБТ тіркеуден бас тартылды': <RestrictIcon />,
	'Тексерудің күші жойылды': <RestrictIcon />,
	'ТСОБТ-не тіркелу': <WaitIcon />,
	'МО-ға пысықтауға': <WaitIcon />,
	'ТСОБТ-не тіркелген': <DoneIcon />,
	Тоқтатылды: <PauseIcon />,
	Аяқталды: <DoneIcon />,
	Жаңартылды: <WaitIconGreen />,
}

const mapColorsToStatusKz = {
	'ТСОБТ тіркеуден бас тартылды': '#FC1313',
	'Тексерудің күші жойылды': '#FC1313',
	'ТСОБТ-не тіркелу': '#F19204',
	'МО-ға пысықтауға': '#F19204',
	'ТСОБТ-не тіркелген': '#17B67C',
	Тоқтатылды: '#F19204',
	Аяқталды: '#17B67C',
	Жаңартылды: '#17B67C',
}

export const CheckStatusColumn = ({ status }) => {
	const lang = getCurrentLanguage()
	const mapColorsToStatus = lang === 'rus' ? mapColorsToStatusRu : mapColorsToStatusKz
	const mapIconToStatus = lang === 'rus' ? mapIconToStatusRu : mapIconToStatusKz

	return (
		<div className={styles.statusColumn} style={{ color: mapColorsToStatus[status?.trim()] }}>
			{mapIconToStatus[status?.trim()]}
			<Typography variant={'text_inter'}>{status}</Typography>
		</div>
	)
}

export const Checks = () => {
	const lang = getCurrentLanguage()
	const langPrefix = lang === 'rus' ? 'Ru' : 'Kz'
	const { t } = useTranslation()
	const [currentPage, setCurrentPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const [totalPages, setTotalPages] = React.useState(0)
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const fetchFunction = useCallback(
		async (reqestedBin) => {
			return safetyService.getChecks(currentPage, rowsPerPage, reqestedBin)
		},
		[currentPage, rowsPerPage]
	)

	const { data, isEmpty, loading } = useFetchData({
		bin: activeCompany.bin,
		fetchFunction,
		defaultValue: defaultInsurance,
	})

	const dataTable = data?.check?.map((item) => {
		return {
			...item,
			duration: `${item.checkBeginDate} - ${item.checkEndDate}`,
			inspector: `${item.userLastName ?? ''} ${item.userFirstName ?? ''}`,
		}
	})

	useEffect(() => {
		if (data?.total) {
			setTotalPages(Math.ceil(data.total / rowsPerPage))
		}
	}, [data])

	if (loading) {
		return (
			<RedirectUnAuthUser>
				<ProfileTemplate title={t('ckp_checks')}>
					<LoadingBlocker />
				</ProfileTemplate>
			</RedirectUnAuthUser>
		)
	}

	if (isEmpty || !data.total) {
		return (
			<RedirectUnAuthUser>
				<ProfileTemplate title={t('ckp_checks')}>
					<InfoDevelopment title={t('ckp_no_info_on_your_enterprise')} small={false} />
				</ProfileTemplate>
			</RedirectUnAuthUser>
		)
	}

	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={t('ckp_checks')}>
				<div className={styles.checksContainer}>
					<div className={styles.statisticContainer}>
						<Typography variant={'title_sm_inter'}>{t('ckp_statistics')}</Typography>
						<div className={styles.statisticGrid}>
							<div className={styles.statisticHeader}>
								<Typography variant={'subtitle_inter'}>{t('ckp_checks')}</Typography>
								<Typography variant={'subtitle_inter'}>{t('ckp_measures_taken')}</Typography>
							</div>
							<Indicator
								title={t('ckp_total_professional_control')}
								icon={<NoteIcon />}
								value={data?.total}
								subValue={`(${data?.checkTypeOtbScheduled}/ ${data?.checkTypeOtbUnScheduled} / ${data?.checkTypeOtbAdministrative})`}
							/>
							<Indicator
								title={t('ckp_active_completed')}
								icon={<NoteIcon />}
								value={`${data?.countActive} / ${data?.countNotActive}`}
							/>
							<Indicator title={t('ckp_instructions_issued')} icon={<OrangeNoteIcon />} value={data?.prescription} />
							<Indicator
								title={t('ckp_administrative_cases_initiated')}
								icon={<RedNoteIcon />}
								value={data?.administrativeMatter}
							/>
						</div>
					</div>
					<Table tableName="social-leaves" data={dataTable} emptyPlaceholder="-">
						<TableColumn dataKey="registrationNumberKpsisu" heading={t('ckp_KPSU_number')} />
						<TableColumn dataKey={`checkTypeName${langPrefix}`} heading={t('ckp_inspection_type')} />
						<TableColumn
							dataKey={`statusName${langPrefix}`}
							renderCell={(item) => {
								return <CheckStatusColumn status={item} />
							}}
							heading={t('ckp_status')}
						/>
						<TableColumn dataKey="duration" heading={t('ckp_execution_period')} />
						<TableColumn dataKey="inspector" heading={t('ckp_inspector')} />
					</Table>

					<HrPagination
						id="hr-pagination"
						rowsPerPageitems={[
							{ size: 10, label: '10' },
							{ size: 50, label: '50' },
							{ size: 100, label: '100' },
							// { size: null, label: 'pagination.show_all' },
						]}
						rowsPerPage={rowsPerPage}
						numberOfElements={data?.check?.length || 0}
						totalElements={data?.total || 0}
						totalPages={totalPages}
						handleChangePage={(_, page) => {
							setCurrentPage(page - 1)
						}}
						handleChangeRowsPerPage={(rows) => {
							setRowsPerPage(rows)
							setCurrentPage(0)
						}}
						page={currentPage}
					/>
				</div>
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}
