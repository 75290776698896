import { request } from '@/shared/api/request'
import { defaultInsurance, defaultPassport, mockedInsurance } from '@pages/CompanyPassport/dfault'
import index from 'react-select'
const headers = {
	'Content-Type': 'application/json',
}
export const safetyService = {
	getBasicInfo: async (bin) => {
		// return defaultPassport
		return request({ url: '/safety/gendata/getBasicInfo?bin=' + bin, method: 'GET', headers })
	},

	getObjects: async (bin) => {
		return request({ url: '/safety/object/getObjects?bin=' + bin, method: 'GET', headers })
	},

	getAccidentInsurance: async (page, size, bin) => {
		// const satrtIndex = page > 0 ? page * size : 0
		// return {
		// 	...mockedInsurance,
		// 	osrnsContracts: mockedInsurance.osrnsContracts.slice(page > 0 ? page * size : 0, satrtIndex + size),
		// }
		return request({
			url: `/safety/insurance/getAccidentInsurances?bin=${bin}&page=${page}&size=${size}`,
			method: 'GET',
			headers,
		})
	},

	getFullInfo: async (bin) => {
		return request({ url: '/safety/gendata/getFullInfo?bin=' + bin, method: 'GET', headers })
	},

	getChecks: async (page, size, bin) => {
		return request({ url: `/safety/getChecks?bin=${bin}&page=${page}&size=${size}`, method: 'GET', headers })
	},

	getViolationMonitoring: async (page, size, bin) => {
		return request({
			url: `/safety/getViolationMonitoring?bin=${bin}&page=${page}&size=${size}`,
			method: 'GET',
			headers,
		})
	},

	generatePdf: async (bin) => {
		return request({ url: `/safety/gendata/generatePdf?bin=${bin}`, method: 'GET', responseType: 'blob' })
	},
}
